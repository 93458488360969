import * as React from 'react'
import { graphql } from "gatsby"
import ItemView from '../../components/itemview'
import Seo from '../../components/seo'

export const query = graphql`
fragment squareImage on File {
  publicURL
}
query {
  slide1: file(relativePath: { eq: "izdeliya/bokal/1.jpg" }) {
    ...squareImage
  }
  slide2: file(relativePath: { eq: "izdeliya/bokal/2.jpg" }) {
    ...squareImage
  }
  slide3: file(relativePath: { eq: "izdeliya/bokal/3.jpg" }) {
    ...squareImage
  }
  slide11: file(relativePath: { eq: "izdeliya/bokal/11.jpg" }) {
    ...squareImage
  }
  slide22: file(relativePath: { eq: "izdeliya/bokal/22.jpg" }) {
    ...squareImage
  }
  slide33: file(relativePath: { eq: "izdeliya/bokal/33.jpg" }) {
    ...squareImage
  }
  slide1mob: file(relativePath: { eq: "izdeliya/bokal/1mob.jpg" }) {
    ...squareImage
  }
  slide2mob: file(relativePath: { eq: "izdeliya/bokal/2mob.jpg" }) {
    ...squareImage
  }
  slide3mob: file(relativePath: { eq: "izdeliya/bokal/3mob.jpg" }) {
    ...squareImage
  }
  slide11mob: file(relativePath: { eq: "izdeliya/bokal/11mob.jpg" }) {
    ...squareImage
  }
  slide22mob: file(relativePath: { eq: "izdeliya/bokal/22mob.jpg" }) {
    ...squareImage
  }
  slide33mob: file(relativePath: { eq: "izdeliya/bokal/33mob.jpg" }) {
    ...squareImage
  }
  slide4mob: file(relativePath: { eq: "izdeliya/bokal/4mob.jpg" }) {
    ...squareImage
  }
  slide5mob: file(relativePath: { eq: "izdeliya/bokal/5mob.jpg" }) {
    ...squareImage
  }
  slide6mob: file(relativePath: { eq: "izdeliya/bokal/6mob.jpg" }) {
    ...squareImage
  }
  slide4: file(relativePath: { eq: "izdeliya/bokal/4.jpg" }) {
    ...squareImage
  }
  slide5: file(relativePath: { eq: "izdeliya/bokal/5.jpg" }) {
    ...squareImage
  }
  slide6: file(relativePath: { eq: "izdeliya/bokal/6.jpg" }) {
    ...squareImage
  }
  slide44mob: file(relativePath: { eq: "izdeliya/bokal/44mob.jpg" }) {
    ...squareImage
  }
  slide55mob: file(relativePath: { eq: "izdeliya/bokal/55mob.jpg" }) {
    ...squareImage
  }
  slide44: file(relativePath: { eq: "izdeliya/bokal/44.jpg" }) {
    ...squareImage
  }
  slide55: file(relativePath: { eq: "izdeliya/bokal/55.jpg" }) {
    ...squareImage
  }
  video1: file(
    relativePath: {eq: "bokal1.mp4"}
    sourceInstanceName: {eq: "video"}
  ) {
    publicURL
  }
  poster1: file(
    relativePath: {eq: "poster-bokal1.png"}
    sourceInstanceName: {eq: "video"}
  ) {
    publicURL
  }
  video2: file(
    relativePath: {eq: "bokal2.mp4"}
    sourceInstanceName: {eq: "video"}
  ) {
    publicURL
  }
  poster2: file(
    relativePath: {eq: "poster-bokal2.png"}
    sourceInstanceName: {eq: "video"}
  ) {
    publicURL
  }
}
`
/* Make slider using only one group of childrens by childrens prop ex.:group and make urrent group a state changeble by event for outside toggle */

const BokalPage = ({ data }) => {
  return (<>
    <Seo
      title={`Бокал под коньяк`}
    />
    <ItemView
      data={data}
      header={<>Бокал под<br /> коньяк</>}
      maintext={<>Богемское стекло в сочетании с витиеватыми узорами барокко. Тонкое стекло с хорошей прозрачностью позволяет рассмотреть напиток, его цвет и колыханье. Форма чаши обеспечит полное раскрытие палитры аромата и всех вкусовых оттенков. Предлагается комплектом в коробке из благородного дуба, украшенной фамильной монограммой.
        <br />Бокалы выпускаются ограниченной серией для коллекционирования и эстетического наслаждения самых изысканных ценителей.
      </>}
      pricetext={{
        slvr: <>
          <div className={`text-primary is-size-6-touch`}>Цена комплекта<br />из&nbsp;<span className={`text-standart`}>двух</span>&nbsp;бокалов</div>
          <div><span className={`is-size-2 is-size-3-touch`}>189000 <span className={`is-size-3 is-size-5-touch`}>руб</span></span></div>
        </>,
        brs: <>
          <div className={`text-primary is-size-6-touch`}>Цена комплекта<br />из&nbsp;<span className={`text-standart`}>двух</span>&nbsp;бокалов</div>
          <div><span className={`is-size-2 is-size-3-touch`}>99000 <span className={`is-size-3 is-size-5-touch`}>руб</span></span></div>
        </>
      }}
    /></>
  )
}

export default BokalPage
